<template>
  <div class="app-container">
    <eHeader ref="header" :query="query" @toQuery="toQuery" @clear="clearHandle"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="编号" width="80"/>
        <el-table-column prop="accountType" label="账户类型"/>

        <el-table-column prop="login" label="订阅者">
        <template slot-scope="scope">
          <span class="span-item">{{scope.row.copyAlias}}</span>
           <span class="span-item">{{scope.row.copyLogin}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="signalLogin" label="信号源">
        <template slot-scope="scope">
          <span class="span-item">{{scope.row.signalAlias}}</span>
          <span class="span-item">{{scope.row.signalLogin	}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="priceType" label="收费方式" width="150">
        <template slot-scope="scope">
           <span class="span-item" >{{scope.row.priceType}}</span>
           <span v-if="scope.row.accountType=='MAM'">
             <span v-if="scope.row.extraType==0 && !scope.row.isFree" class="span-item">表现费：无</span>
            <span v-else class="span-item">
              <el-tooltip class="item" effect="dark" placement="top-start">
                <div slot="content">
                   <div v-for="(item,index) in scope.row.extraType"  :key="index">
                      {{item}} <br/>
                    </div>
                </div>
                <span class="span-item" >表现费：...</span>
              </el-tooltip>
            </span>
           </span>

        </template>
      </el-table-column>
      <el-table-column prop="subPeriod" label="订阅周期">
        <template slot-scope="scope">
          <span>{{scope.row.subPeriod==''?'-':scope.row.subPeriod}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{scope.row.status== 0 ?'无效':scope.row.status== 1 ?'有效':scope.row.status== 2 ?'过期':"取消订阅"}}</span>
        </template>
      </el-table-column>
      <el-table-column label="平仓方式">
        <template slot-scope="scope">
          <span>{{scope.row.closeMode==1?"跟随平仓":"自主平仓"}}</span>
        </template>
      </el-table-column>
       <el-table-column prop="createTime" label="提交时间">
        <template slot-scope="scope">
          <span>{{scope.row.createTime}}</span>
        </template>
      </el-table-column>
        <el-table-column prop="expireDate" label="到期时间">
            <template slot-scope="scope">
                <span v-if="scope.row.expireDate==null">-</span>
                <span v-else>{{scope.row.expireDate}}</span>
            </template>
        </el-table-column>
    </el-table>
    <!--分页组件-->
      <public-pagination
              :total-page="total"
              :current-page="page"
              @pageChange="pageChange"
              @sizeChange="sizeChange"
      ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/copy/subflow/header'
import viewDetail from '@/components/copy/subflow/view'
export default {
  name:'subflow',
  components: { eHeader,viewDetail },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      dialogFormVisible:false,
      flatformdata:[],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/crm/querySubTransList'
      const sort = 'createTime,desc'
      const query = this.query;
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
      }
      const status = query.status;
      const startTime = query.startTime;
      const endTime = query.endTime;
      if (status !== "" && status !== null) {
        this.params["status"] = status;
      }
      if (this.query.copyInfo !== "" && this.query.copyInfo !== null) {
        this.params["copyInfo"] = this.query.copyInfo;
      }
      if (this.query.signalInfo !== "" && this.query.signalInfo !== null) {
        this.params.signalInfo = this.query.signalInfo;
      }
      if (startTime !== undefined && startTime !== "" && startTime !== null) {
        this.params["startTime"] = parseTime(startTime);
      }
      if (endTime !== undefined && endTime !== "" && endTime !== null){
        this.params["endTime"] = parseTime(endTime);
      }
      if (this.$refs.header.$refs.accountType.businessType) {
          this.params['businessType'] = this.$refs.header.$refs.accountType.businessType
      }
      return true
    },
    toClearInit() {
      this.query = {}
      if (this.$refs.header.$refs.accountType.businessType) {
          this.$refs.header.$refs.accountType.businessType=''
      }
      this.init()
    },
    clearHandle(){
      this.toClearInit();
    }
  }
}
</script>

<style scoped>
.link-sum{
  color:blue;
  text-decoration:underline;
  cursor:pointer;
}
.link-sum:hover {
  text-decoration:underline;
   color:red;
}
.span-item{
  display: block;
}
</style>
