<template>
  <div class="button-div">
    <el-button v-if="!isCheck&&checkPermission(['ADMIN','COMPLAINT_ALL','COMPLAINT_SELECT'])&&data.status==7" size="mini" type="primary" @click="to">{{'处理'}}</el-button>
    <el-button v-if="!isCheck&&checkPermission(['ADMIN','SUBSCRIPTION_ALL','SUBSCRIPTION_SELECT'])&&data.status==8" size="mini" type="success" @click="toview">{{'查看'}}</el-button>
    <el-button v-if="!isCheck&&checkPermission(['ADMIN','SUBSCRIPTION_ALL','SUBSCRIPTION_SELECT'])&&data.status==5" size="mini" type="success" @click="toview">{{'查看'}}</el-button>
    <detail ref="detail" :sup_this="sup_this" :data="data" :is-handle="true"/>
    <detail ref="viewdetail" :sup_this="sup_this" :data="data" :is-handle="false"/>
  </div>
</template>
<script>
import detail from "./detail";
import checkPermission from '@/utils/permission'
import { subComplaint,SubSerial } from '@/api/copy/srCommunitySubSerial.js'
import { log } from 'util';
export default {
  components: { detail },
  props: {
    isCheck: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      smsCode: "",
      show: true,
      count: "",
      timer: null,
      id: this.data.id
    };
  },
  methods: {
    checkPermission,
    to() {
      const params = {
        subTransId:this.data.id
      }
      subComplaint(params).then(res => {
        const _this = this.$refs.detail;
        _this.dialog = true
        _this.subData = res
      }).catch(err => {

      })
    },
    toview() {
      const params = {
        subTransId: this.data.id
      }
      SubSerial(params).then(res => {
        const _this = this.$refs.viewdetail;
        _this.dialog = true
        _this.subData = res
      }).catch(err => {

      })
    },
  }
};
</script>

<style scoped>
.button-div {
  display: inline-block;
  margin-right: 3px;
}
.code-font {
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.send-li li {
  display: inline-block;
  margin-right: 20px;
}
.count {
  padding: 10px 30px;
  background-color:rgb(30,144,255);
  color: aliceblue;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor:pointer;
}
</style>
